import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//boostrap
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js';
//font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
//rtl material
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/styles';
import { QuestionaireProvider } from './stores/questionnaire.store';
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

library.add(fas)

//ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
    <QuestionaireProvider>
        <StylesProvider jss={jss}>
            <Router>
                <App />
            </Router>
        </StylesProvider>
    </QuestionaireProvider>
    , document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
