import { FC } from "react";

interface SuggestionPhoneProps {
    phone: string;
}

const SuggestionPhone: FC<SuggestionPhoneProps> = ({ phone }) => {

    const handleClick = () => {
    }

    return (
        <a
            href={`tel:${phone}`}
            rel="noreferrer"
            onClick={handleClick}
        >
            {phone}
        </a>
    );
}

export default SuggestionPhone;