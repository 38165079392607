import { FC, useMemo, useState } from 'react';

import { hospitalCenters, HOSPITAL_CENTERS } from "../consts/hospitalCenters";
import HomeTopBar from '../components/HomeTopBar';

import "../scss/HospitalCenter.scss";
import HospitalTable from '../components/HospitalTable';

const headers = [
    {
        title: "בית חולים",
        weight: 3
    },
    {
        title: "עיר",
        weight: 3
    },
    {
        title: "גיל מאושפזים",
        weight: 3
    },
    {
        title: "סוג בית חולים",
        weight: 3
    },
    {
        weight: 1
    },
    {
        weight: 1
    },
    {
        weight: 1
    }
]

const HospitalCenter: FC = () => {
    const [district, setDistrict] = useState("");
    const [ageGroup, setAgeGroup] = useState("");
    const [type, setType] = useState("");
    const [search, setSearch] = useState("");

    const filteredData = useMemo(() => {
        let data: hospitalCenters = [...HOSPITAL_CENTERS];
        //not empty, then filter
        if (ageGroup !== "") {
            data = data.filter((center) => { return center.ageGroup === ageGroup || center.ageGroup === "כל הגילאים" });
        }
        if (district !== "") {
            data = data.filter((center) => { return center.district === district });
        }
        if (type !== "") {
            data = data.filter((center) => { return center.type === type });
        }
        if (search !== "") {
            data = data.filter((center) => { return center.hospitalName.includes(search) || center.city?.includes(search) });
        }
        return data.sort((a, b) => {
            return a.hospitalName > b.hospitalName ? 1 : -1
        });
    }, [district, ageGroup, type, search]);

    return (
        <div className="hospital_center_container">
            <HomeTopBar />
            <div className="hospital_center_main_container">
                <div className="input_container" dir="rtl">
                    <input type="text" value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder="חיפוש..."/>
                    <select name="" id="" value={district} onChange={(e) => { setDistrict(e.target.value) }}>
                        <option value="">כל המחוזות</option>
                        {
                            HOSPITAL_CENTERS.map(center => center.district)
                                .filter((district, index, self) => {
                                    return self.indexOf(district) === index;
                                })
                                .map((district, i) => {
                                    return (
                                        <option key={i} value={district}>{district}</option>
                                    )
                                })
                        }
                    </select>
                    <select name="" id="" value={ageGroup} onChange={(e) => { setAgeGroup(e.target.value) }}>
                        <option value="">
                            כל הגילאים
                        </option>
                        <option value="בוגרים">
                            בוגרים
                        </option>
                        <option value="קטינים">
                            קטינים
                        </option>
                    </select>
                    <select name="" id="" value={type} onChange={(e) => { setType(e.target.value) }}>
                        <option value="">
                            כל הסוגים
                        </option>
                        {
                            HOSPITAL_CENTERS.map(center => center.type)
                                .filter((type, index, self) => {
                                    return self.indexOf(type) === index;
                                })
                                .map((type, i) => {
                                    return (
                                        <option key={i} value={type}>{type}</option>
                                    )
                                })
                        }
                    </select>
                </div>
                <div className="table_container">
                    <HospitalTable data={filteredData} headers={headers} dir="rtl" />
                </div>
            </div>
        </div>
    );
}

export default HospitalCenter;