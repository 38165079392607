import { action, autorun, computed, makeObservable, observable } from 'mobx';
import axios from 'axios';

import Condition from '../common/interfaces/Condition.interface';

import Question from '../common/interfaces/Question.interface';
import Section from '../common/interfaces/Section.interface';
import { QUESTIONAIRE_STORAGE_NAME } from '../consts/keys';

import validateQuestions from '../common/functions/validateQuestions';
import { ETHIOPIA_ANSWER, IMMIGRATION_QUESTION, RECENTLT_IMMIGRATED } from '../consts/specialQuestions';
import { hebrewUserQuestions } from '../consts/getUserQuestions';

class QuestionsStore {
    questions: Question[] = [];
    questionsCount = 39;
    questionIndex = 0;

    sections: Section[] = [];

    gotAll = false;

    constructor() {
        makeObservable(this, {
            questions: observable,
            questionsCount: observable,
            questionIndex: observable,
            sections: observable,
            currentQuestion: computed,
            currentHeader: computed,
            setAnswer: action,
            addData: action,
            setQuestionsCount: action,
            resetQuestions: action,
            goBack: action,
            restore: action,
            start: action
        });

        this.setAnswer = this.setAnswer.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    get currentQuestion(): Question | null {
        if (this.questionIndex >= this.questions.length) {
            return null;
        }

        return this.questions[this.questionIndex];
    }

    get currentHeader(): string | null {
        const currentQuestion = this.currentQuestion;
        if (!currentQuestion) {
            return null;
        }

        const section = this.sections.find(section => section.name === currentQuestion.section);
        if (!section) {
            return null;
        }

        return section.header || null;
    }

    get canGoBack(): boolean {
        return this.questionIndex !== 0;
    }

    getQuestionsScore(): { questionNumber: number, section: string, score: number }[] {
        const data = this.questions.map((question, index) => ({
            questionNumber: question.questionNumber,
            section: question.section,
            score: this.getQuestionScore(index)
        })).filter(question => question.questionNumber > 0);

        return data;
    }

    getQuestionScore(index: number): number {
        const question = this.questions[index];

        if (question.selectedIndex === undefined) {
            return 0;
        }

        //עלה מאתיופיה - 2 נקודות
        if (question.questionNumber === IMMIGRATION_QUESTION && question.selectedIndex === RECENTLT_IMMIGRATED && this.questions[index - 1].selectedIndex === ETHIOPIA_ANSWER) {
            return 2;
        }

        return question.answers[question.selectedIndex].score;
    }

    setQuestionsCount(count: number) {
        this.questionsCount = count;
    }

    resetQuestions(): void {
        this.questions = this.questions.filter(question => question.isUserQuestion);
        this.sections = [];
    }

    start(userQuestions: Question[]): void {
        this.questionIndex = 0;
        this.questions = userQuestions;
        this.gotAll = false;
    }

    addData(questions: Question[], sectionName: string, sectionHeader: string | undefined, gotAll: boolean): void {
        // this.questions.push(...questions);
        //TODO i dont understand the difference
        this.questions = [...this.questions, ...questions];
        this.sections.push({
            name: sectionName,
            header: sectionHeader
        });
        this.gotAll = gotAll;
    }

    setAnswer(answerIndex: number): void {
        this.questions[this.questionIndex].selectedIndex = answerIndex;
    }


    goBack(): void {
        const lastIndex = this.findLastQuestionIndex();

        const currentQuestion = this.currentQuestion;
        if (currentQuestion) {
            currentQuestion.selectedIndex = undefined;
        }
        this.questionIndex = lastIndex;
    }

    findLastQuestionIndex(): number {
        for (let i = this.questionIndex - 1; i >= 0; i--) {
            if (this.questions[i].selectedIndex !== undefined) {
                return i;
            }
        }

        // will never happen - typescript
        return 0;
    }

    skipQuestion(): boolean {
        if (!this.currentQuestion?.questionCondition) {
            return false;
        }
        return !this.calculateCondition(this.currentQuestion.questionCondition);
    }

    skipSection(): boolean {
        if (!this.currentQuestion?.sectionCondition) {
            return false;
        }
        return this.calculateCondition(this.currentQuestion.sectionCondition);
    }

    calculateCondition(condition: Condition): boolean {
        if (condition.and) {
            return this.calculateCondition(condition.and[0]) && this.calculateCondition(condition.and[1]);
        }

        if (condition.or) {
            return this.calculateCondition(condition.or[0]) || this.calculateCondition(condition.or[1]);
        }

        return this.calculateEqual(condition);
    }

    calculateEqual(condition: Condition): boolean {
        const [questionStr, answerStr] = condition.eq!;
        const answerIndex = parseInt(answerStr.replace("answer_", "")) - 1;
        const questionNumber = parseInt(questionStr.replace("q", ""));

        const question = this.questions.find(question => question.questionNumber === questionNumber);

        //TODO think more about it - no question
        if (!question) {
            return false;
        }

        return question.selectedIndex === answerIndex;
    }


    saveStatus(): void {
        if (this.questions.length === 0) {
            return;
        }

        const data = {
            questions: this.questions,
            questionsCount: this.questionsCount,
            questionIndex: this.questionIndex,
            gotAll: this.gotAll,
            sections: this.sections
        };
        sessionStorage.setItem(QUESTIONAIRE_STORAGE_NAME, JSON.stringify(data));
        sessionStorage.setItem("ts", JSON.stringify(new Date().getTime()));
    }

    deleteStorage(): void {
        sessionStorage.removeItem(QUESTIONAIRE_STORAGE_NAME);
    }

    canRestore(): boolean {
        const strData = sessionStorage.getItem(QUESTIONAIRE_STORAGE_NAME);
        if (!strData) {
            return false;
        }

        try {
            const data = JSON.parse(strData);
            const isValid = validateQuestions(data);

            if (!isValid) {
                return false;
            }

            return true;
        } catch (err) {
            return false;
        }
    }

    restore(): void {
        const strData = sessionStorage.getItem(QUESTIONAIRE_STORAGE_NAME);
        if (!strData) {
            return;
        }

        try {
            const data = JSON.parse(strData);

            this.questions = data.questions;
            this.questionsCount = data.questionsCount;
            this.questionIndex = data.questionIndex;
            this.gotAll = data.gotAll;
            this.sections = data.sections;
        } catch (err) {
        }
    }

    //#region FACT
    sendFact(): void {
        const currentQuestion = this.currentQuestion;
        if (!currentQuestion) {
            // console.log("this really should not happen - no current question for save fact");
            //this really should not happen
            return;
        }

        const lastQuestionIndex = this.findLastQuestionIndex();
        const lastQuestion = this.questions[lastQuestionIndex];

        if (!currentQuestion.isUserQuestion && !lastQuestion.isUserQuestion) {

            this.sendRegularFact(lastQuestion, currentQuestion);

        } else if (currentQuestion.isUserQuestion && lastQuestion.isUserQuestion) {
            this.sendUserFact(lastQuestionIndex, lastQuestion.selectedIndex!, this.questionIndex);

        }
    }

    async sendRegularFact(lastQuestion: Question, currentQuestion: Question): Promise<void> {
        try {
            await axios.post("/api/uuid-fact/question", {
                lastQuestionNumber: lastQuestion.questionNumber,
                lastAnswerIndex: lastQuestion.selectedIndex,
                currentQuestionNumber: currentQuestion.questionNumber
            });
        } catch (err) {
            console.log("can't save fact");
            console.log(err);
        }
    }

    async sendUserFact(lastQuestionIndex: number, lastQuestionSelectedIndex: number, currentQuestionIndex: number): Promise<void> {
        const lastQuestion = hebrewUserQuestions[lastQuestionIndex];
        const currentQuestion = hebrewUserQuestions[currentQuestionIndex];

        try {
           await axios.post("/api/uuid-fact/special-question", {
              lastQuestionNumber: lastQuestion.questionNumber,
              lastQuestionAnswer: lastQuestion.answers[lastQuestionSelectedIndex].text,
              lastQuestionText: lastQuestion.question,
              currentQuestionNumber: currentQuestion.questionNumber,
              currentQuestionText: currentQuestion.question
            });
        } catch (err) {
            console.log("can't save user question fact");
            console.log(err);
        }
    }
    //#endregion
}

export const questionsStore = new QuestionsStore();

autorun(() => questionsStore.saveStatus());