import LANGUAGES from "../../consts/languages";

function validateUser(data: any): boolean {
    if (typeof data !== "object") {
        return false;
    }

    if (typeof data['language'] !== "string" || !LANGUAGES.some(language => language.symbol === data['language'])) {
        return false;
    }

    return true;
}

export default validateUser;