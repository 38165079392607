import { FC } from "react";

import '../scss/YoutubeSuggestion.scss';

interface YoutubeSuggestionProps {
    videoId: string;
}

const YoutubeSuggestion: FC<YoutubeSuggestionProps> = ({ videoId }) => {
    return (
        <iframe
            className="youtube_iframe"
            src={`https://www.youtube.com/embed/${videoId}`}
            allowFullScreen
            title={`suicide prevention video - https://www.youtube.com/watch?v=${videoId}`}
        />
    );
}

export default YoutubeSuggestion;