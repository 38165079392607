import { FC } from 'react';

import '../scss/HomeTopBar.scss';

const HomeTopBar: FC = () => {
    return (
        <div className="home_top_bar_container">
            <img src="/images/hilma.png" alt='הילמה- הייטק למען החברה' title='הילמה- הייטק למען החברה' className="hilma_logo"/>

            <img src="/images/health-logo.png" alt="משרד הבריאות" title="משרד הבריאות" />
            <img src="/images/shivyon.png" alt="מערך הדיגיטל הלאומי" title="מערך הדיגיטל הלאומי" />
            <img src="/images/avoda-new.png" alt="משרד הרווחה והביטחון החברתי" title="משרד הרווחה והביטחון החברתי" />
            <img src="/images/joint.png" alt="ג'וינט אלכא" title="ג'וינט אלכא" className="joint" />
            <img src="/images/suicide-research-institution.png" alt="המרכז לחקר האובדנות והכאב הנפשי" title="המרכז לחקר האובדנות והכאב הנפשי" className="joint" />
            <img src="/images/suicide-prevention-unit.png" alt="התוכנית הלאומית למניעת אובדנות" title="התוכנית הלאומית למניעת אובדנות" className="joint" />
            <img src="/images/herzlia.png" alt="הבינתחומי הרצליה" title="הבינתחומי הרצליה" className="joint" />

        </div>
    );
}

export default HomeTopBar;