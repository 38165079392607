import { FC } from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router';
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

import { useQuestionaire } from '../stores/questionnaire.store';

import AnswerBox from "../components/AnswerBox";
import ProgressBar from "../components/ProgressBar";
// import Loading from '../components/Loading';

import QuestionaireTranslation from '../translations/questionaire.translation';
// import useLongLoading from '../common/hooks/useLongLoading';

import "../scss/Questionnaire.scss";
import StopQuestionnairePopup from '../components/StopQuestionnairePopup';
import ErrorPopup from '../components/ErrorPopup';

const Questionaire: FC = () => {

    const questionaireStore = useQuestionaire();

    const currentQuestion = questionaireStore.questionsStore.currentQuestion;
    const header = questionaireStore.questionsStore.currentHeader;
    const { language } = questionaireStore.userStore;

    const showLoading = currentQuestion === null;//useLongLoading(currentQuestion === null, 1000);

    //language state is the only one not present on page
    // if (questionaireStore.state === "language" || (questionaireStore.state === "questions" && !questionaireStore.userStore.isAuthenticated)) {
    //     return <Redirect to="/" />;
    // }

    if (!questionaireStore.userStore.isAuthenticated || !questionaireStore.questionsStore.questions.length) {
        return <Redirect to="/" />;
    }

    const Arrow = questionaireStore.userStore.direction === "rtl"
        ?
        KeyboardArrowRight
        :
        KeyboardArrowLeft;

    return (
        <div className="questionnaire_container" dir={questionaireStore.userStore.direction}>
            <div className="questionnaire_inner_container">
                <div className="question_header">
                    {header}
                </div>
                <div className="question_container">
                    {
                        !showLoading && currentQuestion
                            ?
                            <>
                                <span className="question">
                                    {currentQuestion.question}
                                </span>
                                <div>
                                    <AnswerBox selected={currentQuestion.selectedIndex} setSelected={questionaireStore.setAnswer} answers={currentQuestion.answers} />
                                </div>
                                <div style={{ display: "flex" }}>

                                    {
                                        questionaireStore.showNextButton
                                            ?
                                            <button disabled={currentQuestion.selectedIndex === undefined} className="next_button" onClick={questionaireStore.nextStep} style={questionaireStore.userStore.direction === "rtl" ? { "left": "6vw" } : { "right": "6vw" }}>
                                                {QuestionaireTranslation.NEXT_BUTTON[language]}
                                            </button>
                                            :
                                            null
                                    }
                                </div>
                            </>
                            :
                            // <Loading />
                            null
                    }
                </div>
                <div className="progress_and_button_container">
                    {
                        questionaireStore.questionsStore.canGoBack
                            ?
                            <button onClick={questionaireStore.goBack}>
                                <Arrow className="previous_button" />
                            </button>
                            :
                            null
                    }
                    <ProgressBar max={questionaireStore.questionsStore.questionsCount} progress={questionaireStore.questionsStore.questionIndex} />
                </div>
            </div>
            <StopQuestionnairePopup />
            <ErrorPopup isOpen={questionaireStore.errorOccurred && !currentQuestion} onClick={questionaireStore.tryAgain} />
        </div >
    );
}

export default observer(Questionaire);