import { FC } from "react";
import { ParsedSuggestion } from "../common/types/ParsedSuggestion.type";
import SingleSuggestion from "./SingleSuggestion";

interface SuggestionProps {
    suggestion: ParsedSuggestion;
}

const Suggestion: FC<SuggestionProps> = ({ suggestion }) => {
    return (
        <span>
            {
                suggestion.map((singleSuggestion, index) => <SingleSuggestion key={index} singleSuggestion={singleSuggestion} />)
            }
        </span>
    );
}

export default Suggestion;