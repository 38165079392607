import { FC } from "react";
import { observer } from "mobx-react";

import GenericPopup from "./GenericPopup";
import { useQuestionaire } from "../stores/questionnaire.store";
import ErrorPopupTranslation from "../translations/errorPopup.translation";

import "../scss/ErrorPopup.scss";

interface ErrorPopupProps {
    isOpen: boolean;
    onClick: () => void;
}

const ErrorPopup: FC<ErrorPopupProps> = ({ isOpen, onClick }) => {
    const questionaireStore = useQuestionaire();
    const { language } = questionaireStore.userStore;

    return (
        <GenericPopup isOpen={isOpen} title={ErrorPopupTranslation.ERROR_OCCURRED[language]}>
            <button className="error_button" onClick={onClick}>
                {ErrorPopupTranslation.TRY_AGAIN[language]}
            </button>
        </GenericPopup>
    );
}

export default observer(ErrorPopup);