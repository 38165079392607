import createTranslation from "./createTranslation";

const returnPopupTranslation = createTranslation({
    QUESTION: {
        he: "האם תרצו לחזור למקום בו הפסקתם?",
        en: "Would you like to return to where you left off?",
        ar: "<ar>Would you like to return to where you left off?<ar>",
        am: "<am>Would you like to return to where you left off?<am>",
        ru: "<ru>Would you like to return to where you left off?<ru>"
    },
    RETURN: {
        he: "חזור",
        en: "return",
        ar: "<ar>return<ar>",
        am: "<am>return<am>",
        ru: "<ru>return<ru>"
    },
    RESTART: {
        he: "התחל מחדש",
        en: "restart",
        ar: "<ar>restart<ar>",
        am: "<am>restart<am>",
        ru: "<ru>restart<ru>"
    }
});

export default returnPopupTranslation;