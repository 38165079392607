import { FC } from "react";
import { observer } from "mobx-react";

import { Select, MenuItem, makeStyles, InputBase, withStyles, Theme } from '@material-ui/core';
import { ExpandMore } from "@material-ui/icons";

import { useQuestionaire } from "../stores/questionnaire.store";

interface CustomSelectProps {
    value: string;
    options: { text: string, symbol: string }[];
    onChange: (value: string) => void;
}

const useStylesSelect = makeStyles<Theme, { direction: "rtl" | "ltr" }>(theme => ({
    root: {
        // minWidth: 120,
        borderRadius: 10,
        fontSize: 23,
        color: "#1e0868",
        fontFamily: "Heebo-Medium",
        direction: "rtl",
        // height: 10,
        // padding: 0
        flexDirection: "row-reverse"
    },
    select: {
        // position: "absolute",
        // left: 0
        // paddingRight: 7,
        display: "flex",
        flexDirection: "row",
        paddingRight: 7
    },
    icon: props => {
        if (props.direction === "rtl") {
            return {
                // position: "static",
                right: 'unset',
                left: 0
            }
        }

        return {};
    }
}));

const BootstrapInput = withStyles(theme => ({
    root: {
        minWidth: 120,
        border: "2px solid #1e0868",
        borderRadius: 10,
        fontSize: 23,
        color: "#1e0868",
        fontFamily: "Heebo-Medium",
        direction: "rtl",
        height: "50%"
    }
}))(InputBase);



const CustomSelect: FC<CustomSelectProps> = ({ value, options, onChange }) => {
    const questionaireStore = useQuestionaire();

    const { direction } = questionaireStore.userStore;

    const selectClasses = useStylesSelect({ direction });

    return (
        <Select
            value={value}
            onChange={(e) => onChange(e.target.value as string)}
            input={<BootstrapInput />}
            classes={{ root: selectClasses.root, select: selectClasses.select, icon: selectClasses.icon }}
            IconComponent={ExpandMore}
        >
            {options.map((option, index) => (
                <MenuItem key={index} value={option.symbol}>
                    {option.text}
                </MenuItem>
            ))}
        </Select>
    );
}

export default observer(CustomSelect);