import { createRef, Suspense, useImperativeHandle } from 'react';
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { History } from "history";
import { observer } from 'mobx-react';

import Home from './scenes/Home';
import Questionaire from './scenes/Questionnaire';
import RiskLevel from './scenes/RiskLevel';
import ReturnPopup from './scenes/ReturnPopup';
import Loading from './components/Loading';
import HospitalCenter from './scenes/HospitalCenter';

import './App.scss';

export const historyRef = createRef<History>();

function App() {
    const history = useHistory();

    useImperativeHandle(historyRef, () => history, [history]);

    return (
        <div className="app-container">
            <Suspense fallback={Loading}>
                <ReturnPopup />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/questionnaire" component={Questionaire} />
                    <Route exact path="/risk-level" component={RiskLevel} />
                    <Route exact path="/center" component={HospitalCenter} />
                    <Redirect to="/" />
                </Switch>
            </Suspense>
        </div>
    );
}

export default observer(App);