import createTranslation from "./createTranslation";

const HomeTranslation = createTranslation({
    START: {
        he: "התחל",
        en: "start",
        ar: "بداية",
        am: "እንጀምር",
        ru: "Начало"
    },
    TITLE: {
        he: "שאלון להערכת סיכון אובדני",
        en: "Suicidal Risk Diagnosis Questionnaire",
        ar: "استبيان تشخيص مخاطر الانتحار",
        am: "ራስን የማጥፋት አደጋ ምርመራ መጠይቅ",
        ru: "Анкета для диагностики суицидального риска"
    },
    TEXT: {
        he: [
            "שלום רב",
            "מטרת השאלון היא לסייע לך לבחון באופן עצמאי את רמת המצוקה והסיכון לעצמך או לאחרים משמעותיים בסביבתך בעת הזו. השאלון נבנה על ידי מומחים לטיפול ועל בסיס שאלונים מתוקפים. בסיום המילוי יתקבל משוב לגבי מצבך והמלצות לגבי פעולות שכדאי לנקוט.",
            "השאלון אנונימי ואינו מצריך מסירה של פרטים מזהים.",
            "השאלון מיועד להערכה ראשונית בלבד ואינו תחליף להערכת סיכון מסודרת על ידי איש מקצוע מומחה."
        ],
        en: ["Greetings,"," The purpose of the questionnaire is to assist you in independently examining the level of distress and risk you or a significant other are currently experiencing."," The questionnaire was developed by treatment experts and is based on validated questionnaires."," Upon completion of the questionnaire, you will receive feedback regarding your situation and recommendations for actions you should take. The questionnaire is anonymous and does not require the provision of personal details."," It is intended for initial assessment purposes only and by no means qualifies as a substitute for a risk assessment conducted by a professional. "],
        ar: ["<ar>שלום רב...<ar>"],
        am: ["<am>שלום רב...<am>"],
        ru: ["<ru>שלום רב...<ru>"]
    }
});

export default HomeTranslation;