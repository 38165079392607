import { action, autorun, computed, makeObservable, observable } from 'mobx';

import parseSuggestion from '../common/functions/parseSuggestion';
import validateRiskLevel from '../common/functions/validateRiskLevel';
import { ParsedSuggestion } from '../common/types/ParsedSuggestion.type';
import { RISK_LEVEL_STORAGE_NAME } from '../consts/keys';

class RiskLevelStore {
    riskLevelName: string | null = null;
    feedbacks: string[] | null = null;
    suggestions: string[] | null = null;

    constructor() {
        makeObservable(this, {
            setData: action,
            riskLevelName: observable,
            feedbacks: observable,
            suggestions: observable,
            restore: action,
            resetData: action,
            formattedFeedbacks: computed,
            formattedSuggestions: computed,
            feedbacksTitle: computed,
            suggestionsTitle: computed
        });
    }

    get formattedFeedbacks(): ParsedSuggestion[] {
        return this.feedbacks!.slice(1).map(parseSuggestion);
    }

    get formattedSuggestions(): ParsedSuggestion[] {
        return this.suggestions!.slice(1).map(parseSuggestion);
    }

    get feedbacksTitle(): string {
        return this.feedbacks![0];
    }

    get suggestionsTitle(): string {
        return this.suggestions![0];
    }

    setData(feedbacks: string[], suggestions: string[], riskLevelName: string): void {
        this.feedbacks = feedbacks;
        this.suggestions = suggestions;
        this.riskLevelName = riskLevelName;
    }

    resetData(): void {
        this.feedbacks = null;
        this.suggestions = null;
        this.riskLevelName = null;
    }

    saveStatus(): void {
        const dataStr = JSON.stringify({
            riskLevelName: this.riskLevelName,
            feedbacks: this.feedbacks,
            suggestions: this.suggestions
        });

        if (this.riskLevelName) {
            sessionStorage.setItem(RISK_LEVEL_STORAGE_NAME, dataStr);
        }
    }

    deleteStorage(): void {
        sessionStorage.removeItem(RISK_LEVEL_STORAGE_NAME);
    }

    canRestore(): boolean {
        const dataStr = sessionStorage.getItem(RISK_LEVEL_STORAGE_NAME);
        if (!dataStr) {
            return false;
        }

        try {
            const data = JSON.parse(dataStr);
            if (!validateRiskLevel(data)) {
                return false;
            }

            return true;
        } catch (err) {
            return false;
        }
    }

    restore(): void {
        const dataStr = sessionStorage.getItem(RISK_LEVEL_STORAGE_NAME);
        if (!dataStr) {
            return;
        }

        try {
            const data = JSON.parse(dataStr);
            if (!validateRiskLevel(data)) {
                return;
            }
            this.riskLevelName = data.riskLevelName;
            this.feedbacks = data.feedbacks;
            this.suggestions = data.suggestions;

        } catch (err) {

        }
    }
}

const riskLevelStore = new RiskLevelStore();
autorun(() => riskLevelStore.saveStatus());

export default riskLevelStore;
