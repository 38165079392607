interface SavedRiskLevel {
    feedbacks: string[];
    suggestions: string[];
    riskLevelName: string;
}

function validateRiskLevel(data: any): data is SavedRiskLevel {
    if (typeof data !== "object") {
        return false;
    }

    if (!isStringArray(data.feedbacks)) {
        return false;
    }

    if (!isStringArray(data.suggestions)) {
        return false;
    }

    if (typeof data.riskLevelName !== "string") {
        return false;
    }

    return true;
}

function isStringArray(arr: any): arr is string[] {
    return Array.isArray(arr) && arr.every(item => typeof item === "string");
}

export default validateRiskLevel;