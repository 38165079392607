import { FC } from 'react';
import "../scss/ProgressBar.scss";

export interface ProgressBarProps {
    max: number;
    progress: number;
}

const ProgressBar: FC<ProgressBarProps> = (props) => {
    const { max, progress } = props;
    const percent = Math.floor((progress * 100) / max);

    return (
        <div className="progress_bar_container">
            <div className="progress_percentage">
                {`${percent}%`}
            </div>
            <div className="progress_bar">
                <div className="progress_fill" style={{ width: `${percent}%` }} />
            </div>
        </div>
    );
}

export default ProgressBar;