import { FC, useMemo } from "react";
import axios from "axios";

import { ParsedLinkSuggestion } from "../common/types/ParsedSuggestion.type";
import Suggestion from "./Suggestion";

interface SuggestionLinkProps {
    suggestionLink: ParsedLinkSuggestion;
}

const SuggestionLink: FC<SuggestionLinkProps> = ({ suggestionLink }) => {

    const sendLinkFact = async () => {
        const url = "/api/uuid-fact/link";

        try {
            await axios.post(url, {
                link: suggestionLink.href
            });
        } catch (err) {
            console.log("can't save link fact");
            console.log(err);
        }
    }

    const href = useMemo(() => {
        if (suggestionLink.href.startsWith("/")) {
            return window.location.origin + suggestionLink.href;
        }

        return suggestionLink.href;
    }, [suggestionLink.href]);

    return (
        <a
            href={href}
            target="_blank"
            rel="noreferrer"
            onClick={sendLinkFact}
        >
            <Suggestion suggestion={suggestionLink.innerSuggestion} />
        </a>
    );
}

export default SuggestionLink;