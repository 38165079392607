import Question from "../common/interfaces/Question.interface";
import LanguageType from "../common/types/language.type";
import StartTranslation from '../translations/start.translation';


function getUserQuestions(language: LanguageType): Question[] {
    return [
        {
            isUserQuestion: true,
            questionNumber: -1,
            section: "DIS RISK",
            question: StartTranslation.FOR_WHOM[language],
            answers: [
                {
                    score: 0,
                    text: StartTranslation.SELF[language],
                    symbol: "self"
                },
                {
                    score: 0,
                    text: StartTranslation.OTHER[language],
                    symbol: "other"
                }
            ]
        },
        {
            isUserQuestion: true,
            questionNumber: 2,
            section: "DIS RISK",
            question: StartTranslation.AGE_GROUP[language],
            answers: [
                {
                    score: 0,
                    text: StartTranslation.UNDER_14[language],
                    symbol: "child"
                },
                {
                    score: 0,
                    text: StartTranslation.FROM_15_TO_18[language],
                    symbol: "teen"
                },
                {
                    score: 0,
                    text: StartTranslation.PLUS_18[language],
                    symbol: "adult"
                },
                {
                    score: 1,
                    text: StartTranslation.PLUS_55[language],
                    symbol: "adult"
                }
            ]
        },
        {
            isUserQuestion: true,
            questionNumber: 1,
            section: "DIS RISK",
            question: StartTranslation.GENDER[language],
            answers: [
                {
                    score: 1,
                    text: StartTranslation.MALE[language],
                    symbol: "male"
                },
                {
                    score: 0,
                    text: StartTranslation.FEMALE[language],
                    symbol: "female"
                },
                {
                    score: 2,
                    text: StartTranslation.TRANSGENDER[language],
                    symbol: "none"
                },
                {
                    score: 1,
                    text: StartTranslation.OTHER_GENDER[language],
                    symbol: "none"
                }
            ]
        },
        {
            isUserQuestion: true,
            questionNumber: 0,
            section: "DIS RISK",
            question: StartTranslation.PREFERRED_PRONOUNS[language],
            answers: [
                {
                    score: 0,
                    text: StartTranslation.MALE[language],
                    symbol: "male"
                },
                {
                    score: 0,
                    text: StartTranslation.FEMALE[language],
                    symbol: "female"
                }
            ],
            questionCondition: {
                or: [
                    { eq: ["q1", "answer_3"] },
                    { eq: ["q1", "answer_4"] }
                ]
            }
        }
    ];
}

export const hebrewUserQuestions = getUserQuestions("he");

export default getUserQuestions;