import { FC } from 'react';
import AnswerButton from "./AnswerButton";
import "../scss/Answers.scss";
import Answer from '../common/interfaces/Answer.interface';

export interface AnswerBoxProps {
    answers: Array<Answer>;
    selected: number | undefined;
    setSelected: (newNumber: number) => void;
}

const AnswerBox: FC<AnswerBoxProps> = (props) => {
    const { answers, selected, setSelected } = props;

    return (
        <div className="answer_box">
            {answers.map((answer, index) => {
                return <AnswerButton key={answer.text} text={answer.text} selected={selected === index} onClick={() => { setSelected(index) }} />
            })}
        </div>
    );
}

export default AnswerBox;