
/*
    \+\+ - ++ - underline
    \*\* - ** - bold
    \[.+\]\(.+\) - [title](link) - link
    ## - phone
    -- - list item
    @@ - youtube link
*/


export const linkRegex = /\[[^\]]+\]\([^)]+\)/;
export const operatorRegex = /\*\*|\+\+|##|--|@@/;

export const onlyOperatorRegex = new RegExp(`^(${operatorRegex.source})$`);
export const suggestionSymbol = new RegExp(`(${operatorRegex.source}|${linkRegex.source})`);