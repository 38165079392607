import { action, computed, makeObservable, observable } from 'mobx';
import axios from 'axios';

import { createMobXContext } from '@hilma/tools';

import { GetQuestionsResponse, GetStartQuestionsResponse } from '../common/interfaces/GetQuestionsResponse.interface';
import { historyRef } from '../App';
import { questionsStore } from './questions.store';
import userStore from './user.store';
import GetRiskLevelResponse from '../common/interfaces/GetRiskLevelResponse';
import riskLevelStore from './riskLevel.store';

import getUserQuestions, { hebrewUserQuestions } from '../consts/getUserQuestions';

class QuestionaireStore {
    userStore = userStore;
    questionsStore = questionsStore;
    riskLevelStore = riskLevelStore;
    timeout: ReturnType<typeof setTimeout> | null = null;

    errorOccurred = false;


    constructor() {
        makeObservable(this, {
            userStore: observable,
            questionsStore: observable,
            riskLevelStore: observable,
            errorOccurred: observable,
            nextStep: action,
            nextSection: action,
            goBack: action,
            setErrorOccurred: action,
            showNextButton: computed,
        });

        this.nextStep = this.nextStep.bind(this);
        this.setAnswer = this.setAnswer.bind(this);
        this.goBack = this.goBack.bind(this);
        this.createUser = this.createUser.bind(this);
        this.tryAgain = this.tryAgain.bind(this);
        this.getRiskLevel = this.getRiskLevel.bind(this);
    }

    get isAuthenticated(): boolean {
        return this.userStore.isAuthenticated && this.canRestore();
    }

    get showNextButton(): boolean {
        return this.questionsStore.currentQuestion?.selectedIndex !== undefined && this.timeout === null;
    }

    setErrorOccurred(value: boolean): void {
        this.errorOccurred = value;
    }


    async createUser(): Promise<void> {
        this.deleteStorage();
        this.userStore.saveStatus();

        // FACT
        const hebrewFirstQuestion = hebrewUserQuestions[0];

        const data = {
            language: this.userStore.language,
            firstQuestionNumber: hebrewFirstQuestion.questionNumber
        };

        try {
            await axios.post("/api/user", data);

            this.questionsStore.start(getUserQuestions(this.userStore.language));

            this.userStore.startQuestionnnaire();
            historyRef.current?.push("/questionnaire");
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async startQuestionaire(): Promise<void> {
        // this.userStore.saveStatus();
        this.setErrorOccurred(false);

        this.questionsStore.resetQuestions();
        const userQuestions = this.questionsStore.questions.filter(question => question.isUserQuestion && question.selectedIndex !== undefined && question.answers[question.selectedIndex].symbol !== "none");
        const userData = userQuestions.map(question => question.answers[question.selectedIndex!].symbol!);

        //FACT
        const lastQuestion = userQuestions[userQuestions.length - 1];
        const hebrewLastQuestion = hebrewUserQuestions.find(question => question.questionNumber === lastQuestion.questionNumber);

        const data = {
            userData,
            lastQuestionNumber: lastQuestion.questionNumber,
            lastQuestionText: lastQuestion.question,
            lastAnswer: hebrewLastQuestion?.answers[lastQuestion.selectedIndex!].text
        };

        try {
            const res = await axios.post<GetStartQuestionsResponse>("/api/question/start-questionnaire", data);
            const { startQuestions, count } = res.data;

            //there are four questions that are not from the database
            const questionsCount = count + 4;
            this.questionsStore.setQuestionsCount(questionsCount);

            this.questionsStore.addData(startQuestions.questions, startQuestions.section, startQuestions.sectionHeader, startQuestions.done);
        } catch (err) {
            console.log(err);
            this.setErrorOccurred(true);
            // this.goBack();
        }
    }

    async getNextQuestions(): Promise<void> {
        this.setErrorOccurred(false);
        try {
            const res = await axios.get<GetQuestionsResponse>("/api/question/next-section");
            this.questionsStore.addData(res.data.questions, res.data.section, res.data.sectionHeader, res.data.done);
        } catch (err) {
            console.log(err);
            this.setErrorOccurred(true);
        }
    }

    setAnswer(answer: number): void {
        this.questionsStore.setAnswer(answer);

        if (this.timeout !== null) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.nextStep();
            this.timeout = null;
        }, 300);
    }

    goBack(): void {
        this.questionsStore.goBack();

        if (this.timeout !== null) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    nextStep(): void {
        if (this.userStore.questionnaireStopped) {
            return;
        }

        if (this.questionsStore.gotAll && this.questionsStore.questionIndex === this.questionsStore.questions.length - 1) {
            this.getRiskLevel();
            return;
        }

        this.nextQuestion();
    }

    async getRiskLevel(): Promise<void> {
        this.setErrorOccurred(false);

        // make sure no old data is shown
        this.riskLevelStore.resetData();
        historyRef.current?.push("risk-level");

        const questionsScore = this.questionsStore.getQuestionsScore();

        // FACT
        const currentQuestion = this.questionsStore.currentQuestion?.selectedIndex !== undefined ? this.questionsStore.currentQuestion : this.questionsStore.questions[this.questionsStore.findLastQuestionIndex()];

        const data = {
            questions: questionsScore,
            lastQuestionNumber: currentQuestion.questionNumber,
            lastAnswerIndex: currentQuestion.selectedIndex
        };

        try {
            const res = await axios.post<GetRiskLevelResponse>("/api/risk-level", data);

            this.riskLevelStore.setData(res.data.feedbacks, res.data.suggestions, res.data.name);


            //if we skipped over the last question - goBack for to the last answered question - in case the user goes back
            if (this.questionsStore.questions[this.questionsStore.questionIndex].selectedIndex === undefined) {
                this.questionsStore.goBack();
            }
        } catch (err) {
            console.log(err);
            this.setErrorOccurred(true);
        }
    }

    nextQuestion(): void {
        if (this.questionsStore.currentQuestion?.isUserQuestion && (this.questionsStore.questionIndex === this.questionsStore.questions.length - 1 || !this.questionsStore.questions[this.questionsStore.questionIndex + 1].isUserQuestion)) {
            this.startQuestionaire();
        }

        if (!this.questionsStore.gotAll && this.questionsStore.questionIndex >= this.questionsStore.questions.length - 3 && !this.questionsStore.currentQuestion?.isUserQuestion) {
            this.getNextQuestions();
        }

        this.questionsStore.questionIndex++;
        //we count here on the fact that if a question hasn't been loaded yet, which meens it's the first question of a section, it cannot have a condition

        if (this.questionsStore.skipQuestion()) {
            this.nextStep();
        } else if (this.questionsStore.skipSection()) {
            this.nextSection();
        } else {
            this.questionsStore.sendFact();
        }
    }

    nextSection(): void {
        for (let i = this.questionsStore.questionIndex + 1; i < this.questionsStore.questions.length; i++) {
            if (this.questionsStore.questions[i].section !== this.questionsStore.questions[this.questionsStore.questionIndex].section) {
                this.questionsStore.questionIndex = i;
                return;
            }
        }

        //! if the first question of the next section has a condition - it will not check it
        this.questionsStore.questionIndex = this.questionsStore.questions.length;
        this.getNextQuestions()
            .then(() => this.questionsStore.sendFact());
    }

    //after error
    tryAgain(): void {
        const lastIndex = this.questionsStore.findLastQuestionIndex();
        const lastQuestion = this.questionsStore.questions[lastIndex];
        if (lastQuestion?.isUserQuestion) {
            this.startQuestionaire();
        } else {
            this.getNextQuestions();
        }
    }

    canRestore(): boolean {
        //check session storage timestamp, if 5 min past then cant restore

        const timestamp = sessionStorage.getItem("ts");
        if (!timestamp) {
            return false;
        }

        let ts = parseInt(timestamp);
        if (Number.isNaN(ts)) {
            sessionStorage.removeItem("ts");
            return false;
        }
        const timeDiff = 1000 * 60 * 5; //5 min
        if (new Date().getTime() - ts > timeDiff) {
            return false;
        }

        if (!this.userStore.canRestore()) {
            return false;
        }

        if (!this.questionsStore.canRestore()) {
            return false;
        }

        return true;
    }

    restore(): void {
        const canRestoreUser = this.userStore.canRestore();
        const canRestoreQuestions = this.questionsStore.canRestore();
        const canRestoreRiskLevel = this.riskLevelStore.canRestore();

        if (canRestoreRiskLevel && canRestoreQuestions && canRestoreUser) {
            this.userStore.restore();
            this.questionsStore.restore();
            this.riskLevelStore.restore();
            historyRef.current?.push("/risk-level");
            return;
        }

        if (canRestoreQuestions && canRestoreUser) {
            this.userStore.restore();
            this.questionsStore.restore();
            historyRef.current?.push("/questionnaire");
            return;
        }

        this.deleteStorage();
        historyRef.current?.push("");
    }

    deleteStorage(): void {
        this.userStore.logout();
        this.userStore.deleteStorage();
        this.questionsStore.deleteStorage();
        this.riskLevelStore.deleteStorage();
        sessionStorage.removeItem("ts");
    }
}

export const questionaireStore = new QuestionaireStore();
(window as any).store = questionaireStore;
export const [QuestionaireContext, QuestionaireProvider, useQuestionaire] = createMobXContext(questionaireStore);