import { FC, useRef } from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router";
import { Collapse } from "@material-ui/core";

import { useQuestionaire } from "../stores/questionnaire.store";

import SendPopup from "../components/SendPopup";
import Suggestion from "../components/Suggestion";
import RiskLevelTranslation from "../translations/riskLevel.translation";
import ErrorPopup from "../components/ErrorPopup";

import "../scss/RiskLevel.scss";
import useIsMobile from "../common/hooks/useIsMobile";
import { useToggle } from "../common/hooks/useToggle";
import { usePopupReducer } from "../common/hooks/usePopupReducer";

const RiskLevel: FC = () => {
  const questionaireStore = useQuestionaire();
  const { direction, language } = questionaireStore.userStore;

  const isMobile = useIsMobile();

  //ui state:
  const { showPopup, dispatch } = usePopupReducer();
  const [collapseIn, toggleCollapse] = useToggle(false);

  const suggestions = useRef<HTMLDivElement | null>(null);

  const closePopup = () => {
    dispatch({ type: "CLOSE" });
  };

  const openPopup = () => {
    dispatch({ type: "OPEN" });
  };

  if (
    !questionaireStore.userStore.isAuthenticated ||
    !questionaireStore.questionsStore.questions.length
  ) {
    return <Redirect to="" />;
  }

  if (!questionaireStore.riskLevelStore.riskLevelName) {
    return (
      <div className="risk_level_container">
        <ErrorPopup
          isOpen={questionaireStore.errorOccurred}
          onClick={questionaireStore.getRiskLevel}
        />
      </div>
    );
  }

  const [firstFeedbacksParagraph, ...feedbacks] =
    questionaireStore.riskLevelStore.formattedFeedbacks;

  return (
    <div className="risk_level_container" dir={direction}>
      {showPopup && (
        <SendPopup suggestion={suggestions.current} closePopup={closePopup} />
      )}
      <div>
        <h2 className="risk_level_title">
          {questionaireStore.riskLevelStore.feedbacksTitle}
        </h2>
        <div className="risk_level_feedback_container">
          {isMobile ? (
            <>
              <Suggestion suggestion={firstFeedbacksParagraph} />
              <Collapse in={collapseIn}>
                {feedbacks.map((feedback, index) => (
                  <p key={index}>
                    <Suggestion key={index} suggestion={feedback} />
                  </p>
                ))}
              </Collapse>
              <button onClick={toggleCollapse} className="show_more">
                {collapseIn
                  ? RiskLevelTranslation.SHOW_LESS[language]
                  : RiskLevelTranslation.SHOW_MORE[language]}
              </button>
            </>
          ) : (
            <>
              <Suggestion suggestion={firstFeedbacksParagraph} />
              {feedbacks.map((feedback, index) => (
                <p key={index}>
                  <Suggestion key={index} suggestion={feedback} />
                </p>
              ))}
            </>
          )}
        </div>
      </div>

      <div className="suggestion_container">
        <div ref={suggestions}>
          <div style={{ direction }}>
            <h2 className="risk_level_title">
              {questionaireStore.riskLevelStore.suggestionsTitle}
            </h2>

            {questionaireStore.riskLevelStore.formattedSuggestions.map(
              (suggestion, index) => (
                <div className="risk_level_suggestion_container" key={index}>
                  <Suggestion key={index} suggestion={suggestion} />
                </div>
              )
            )}
          </div>
        </div>
        <button className="suggestion_button" onClick={openPopup}>
          {RiskLevelTranslation.SEND_EMAIL[language]}
        </button>
      </div>
      {process.env.REACT_APP_FEEDBACK_LINK && (
        <a
          className="feedback_link"
          href={process.env.REACT_APP_FEEDBACK_LINK}
          target="_blank"
          rel="noreferrer noopener"
        >
          {RiskLevelTranslation.FILL_FEEDBACK[language]}
        </a>
      )}
    </div>
  );
};

export default observer(RiskLevel);
