import { FC } from "react";

import keywords from '../common/functions/generateKewords';

//keywords for google's algorithm
const Keywords: FC = () => {
    return (
        <div style={{
            position: "absolute",
            top: 0,
            width: 0,
            height: 10,
            overflow: "hidden"
        }}>
            {keywords.map((keyword, index) => (
                <h2 key={index}>
                    {keyword}
                </h2>
            ))}
        </div>
    );
}

export default Keywords;