import { FC } from 'react';
import { observer } from 'mobx-react';

import { useQuestionaire } from '../stores/questionnaire.store';

import "../scss/GenericPopup.scss";

export interface GenericPopupProps {
    isOpen: boolean;
    title: string;
}

const GenericPopup: FC<GenericPopupProps> = ({ isOpen, title, children }) => {
    const questionaireStore = useQuestionaire();
    const { direction } = questionaireStore.userStore;

    if (!isOpen) {
        return null;
    }

    return (
        <div className="generic_popup_page_container" dir={direction}>
            <div className="generic_popup_container">
                <span className="generic_popup_text_container">
                    {title}
                </span>
                {
                    children
                }
            </div>
        </div>
    );
}

export default observer(GenericPopup);