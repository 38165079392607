import { linkRegex, onlyOperatorRegex, suggestionSymbol } from '../../consts/suggestionRegex';
import { ParsedSuggestion } from '../types/ParsedSuggestion.type';
import { SuggestionOperatorType } from '../types/SuggestionOperator.type';


function parseSuggestion(suggestion: string): ParsedSuggestion {
    const parts = suggestion.split(suggestionSymbol);
    return parseSplittedSuggestion(parts);
}

function parseSplittedSuggestion(splittedSuggestion: string[]): ParsedSuggestion {

    const operatorIndex = splittedSuggestion.findIndex(part => part.match(onlyOperatorRegex));
    if (operatorIndex !== -1) {

        const lastIndex = splittedSuggestion.slice(operatorIndex + 1).findIndex(part => part === splittedSuggestion[operatorIndex]) + operatorIndex + 1;

        return [
            ...parseSplittedSuggestion(splittedSuggestion.slice(0, operatorIndex)),
            {
                type: splittedSuggestion[operatorIndex] as SuggestionOperatorType,
                innerSuggestion: parseSplittedSuggestion(splittedSuggestion.slice(operatorIndex + 1, lastIndex))
            },
            ...parseSplittedSuggestion(splittedSuggestion.slice(lastIndex + 1))

        ];
    }

    const linkIndex = splittedSuggestion.findIndex(part => part.match(linkRegex));

    if (linkIndex !== -1) {
        const splittedLink = splittedSuggestion[linkIndex].split("](");

        const link = splittedLink[1].slice(0, -1);
        const text = splittedLink[0].slice(1);

        return [
            ...splittedSuggestion.slice(0, linkIndex),
            {
                type: "link",
                href: link,
                innerSuggestion: parseSuggestion(text)
            },
            ...parseSplittedSuggestion(splittedSuggestion.slice(linkIndex + 1))
        ];
    }

    return splittedSuggestion;
}

export default parseSuggestion;