const keys = [
    ["שאלון", "אבחון", "בדיקה", "מבחן", "בוחן", "ייעוץ"],
    ["סיכון", "מסוכנות", "סכנה", "איום"],
    ["אובדנות", "התאבדות", "דיכאון", "מצוקה", "כאב נפשי", "משבר", "חרדה", "לחץ", "קושי", "קושי רגשי", "מחשבות אובדניות", "תוכנית אבדנית", "ייאוש", "חוסר תקווה"],
];


//Cartesian product of string arrays
/**
 * 
 * @param arr1
 * @param arr2 
 * @returns Cartesian product of string arrays
 * @example ["a", "b"] * ["c", "d"] = ['a', 'b', 'c', 'd', 'a c', 'a d', 'b c', 'b d']
 */
function mult(arr1: string[], arr2: string[]): string[] {
    const res = [...arr1, ...arr2];

    for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
            res.push(arr1[i] + " " + arr2[j]);
        }
    }

    return res;
}

/**
 * 
 * @param keywords 
 * @returns Cartesian product of all the string arrays in an array
 * @example ["a", "b"] * ["c"] * ["d", "e"] = ['a', 'b', 'c', 'a c', 'b c', 'd', 'a d', 'b d', 'c d', 'a c d', 'b c d']
 */
function generateKeywords(keywords: string[][]) {
    let res: string[] = [];
    for (let i = 0; i < keywords.length; i++) {
        res = mult(res, keywords[i]);
    }
    return res;
}

const keywords = generateKeywords(keys);
export default keywords;