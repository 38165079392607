import createTranslation from "./createTranslation";

const QuestionaireTranslation = createTranslation({
    NEXT_BUTTON: {
        he: "הבא",
        ar: "التالي",
        ru: "Далее",
        en: "Next",
        am: "ቀጣይ"
    },
    GO_BACK: {
        he: "חזור",
        en: "Go back",
        ar: "<ar>go back<ar>",
        am: "<am>go back<am>",
        ru: "<ru>go back<ru>"
    },
    TIME_IS_UP: {
        he: "נגמר הזמן למילוי השאלון",
        en: "The time to complete the questionnaire is over",
        ar: "<ar>The time to complete the questionnaire is over<ar>",
        am: "<am>The time to complete the questionnaire is over<am>",
        ru: "<ru>The time to complete the questionnaire is over<ru>"
    },
    RESTART: {
        he: "התחל מחדש",
        en: "restart",
        ar: "<ar>restart<ar>",
        am: "<am>restart<am>",
        ru: "<ru>restart<ru>"
    }
});

export default QuestionaireTranslation;