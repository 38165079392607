import { observer } from 'mobx-react';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { useQuestionaire } from '../stores/questionnaire.store';

import GenericPopup from '../components/GenericPopup';

import QuestionaireTranslation from '../translations/questionaire.translation';

import "../scss/StopQuestionnairePopup.scss";

const StopQuestionnairePopup: FC = () => {
    const questionaireStore = useQuestionaire();
    const { language } = questionaireStore.userStore;

    const history = useHistory();

    const handleClick = () => {
        questionaireStore.deleteStorage();
        history.push("");
    }


    return (
        <GenericPopup
            isOpen={questionaireStore.userStore.questionnaireStopped}
            title={QuestionaireTranslation.TIME_IS_UP[language]}
        >
            <div className="stop_popup_button_container">
                <button onClick={handleClick}>{QuestionaireTranslation.RESTART[language]}</button>
            </div>
        </GenericPopup>
    );
}

export default observer(StopQuestionnairePopup);