import { FC } from "react";

import Suggestion from "./Suggestion";
import { SingleParsedSuggestionType } from "../common/types/ParsedSuggestion.type";
import SuggestionLink from "./SuggestionLink";
import SuggestionPhone from "./SuggestionPhone";

import "../scss/SingleSuggestion.scss";
import YoutubeSuggestion from "./YoutubeSuggestion";

interface SingleSuggestionProps {
    singleSuggestion: SingleParsedSuggestionType;
}

const SingleSuggestion: FC<SingleSuggestionProps> = ({ singleSuggestion }) => {
    if (typeof singleSuggestion === "string") {
        return <span>{singleSuggestion}</span>
    }


    switch (singleSuggestion.type) {
        case "link":
            return <SuggestionLink suggestionLink={singleSuggestion} />;

        case "##":
            return <SuggestionPhone phone={singleSuggestion.innerSuggestion[0] as unknown as string} />

        case "**":
            return (
                <strong>
                    <Suggestion suggestion={singleSuggestion.innerSuggestion} />
                </strong>
            );

        case "++":
            return (
                <span style={{ textDecoration: "underline" }}>
                    <Suggestion suggestion={singleSuggestion.innerSuggestion} />
                </span>
            );

        case "--":
            return (
                <li>
                    <Suggestion suggestion={singleSuggestion.innerSuggestion} />
                </li>
            );

        case "@@":
            return (
                <YoutubeSuggestion videoId={singleSuggestion.innerSuggestion[0] as string} />
            );

        default:
            return null;
    }
}

export default SingleSuggestion;