import { Dispatch, SetStateAction, useCallback, useState } from "react";

/**
 * Custom hook to manage a boolean toggle state.
 * @param {boolean} [defaultValue] - Optional default value for the toggle state.
 * @returns {[boolean, () => void, import("react").Dispatch<import("react").SetStateAction<boolean>>]} - Tuple containing current state, toggle function, and state setter function.
 * @example
 * // Example usage:
 * import React from "react";
 * import { useToggle } from "./useToggle";
 *
 * function ToggleExample() {
 *   // Using the useToggle hook with default value
 *   const [isToggled, toggle, setIsToggled] = useToggle(true);
 *
 *   // Event handler to reset the toggle state
 *   const handleReset = () => setIsToggled(true);
 *
 *   return (
 *     <div>
 *       <h2>Toggle Example</h2>
 *       <p>Current state: {isToggled ? 'On' : 'Off'}</p>
 *       <button onClick={toggle}>Toggle</button>
 *       <button onClick={handleReset}>Reset</button>
 *     </div>
 *   );
 * }
 *
 * export default ToggleExample;
 */
export function useToggle(
  defaultValue?: boolean
): [boolean, () => void, Dispatch<SetStateAction<boolean>>] {
  const [value, setValue] = useState(!!defaultValue);

  const toggle = useCallback(() => setValue((x) => !x), []);

  return [value, toggle, setValue];
}
