import createTranslation from "./createTranslation";

const ErrorPopupTranslation = createTranslation({
    ERROR_OCCURRED: {
        he: "חלה שגיאה",
        en: "An error occurred",
        ar: "<ar>An error occurred<ar>",
        ru: "<ru>An error occurred<ar>",
        am: "<am>An error occurred<am>"
    },
    TRY_AGAIN: {
        he: "נסה שוב",
        en: "try again",
        ar: "<ar>try again<ar>",
        ru: "<ru>try again<ru>",
        am: "<am>try again<am>"
    }
});

export default ErrorPopupTranslation;