import createTranslation from "./createTranslation";

const RiskLevelTranslation = createTranslation({
    // FEEDBACK: {
    //     he: "תשובותיך לשאלון מצביעות על כך ש:",
    //     en: "Feedback",
    //     ar: "<ar>Feedback<ar>",
    //     am: "<am>Feedback<am>",
    //     ru: "<ru>Feedback<ru>"
    // },
    // SUGGESTIONS: {
    //     he: "המלצות",
    //     en: "Suggestions",
    //     ar: "<ar>Suggestions<ar>",
    //     am: "<am>Suggestions<am>",
    //     ru: "<ru>Suggestions<ru>"
    // },
    SHOW_MORE: {
        he: "הראה עוד",
        en: "show more",
        ar: "<ar>show more<ar>",
        am: "<am>show more<am>",
        ru: "<ru>show more<ru>"
    },
    SHOW_LESS: {
        he: "הראה פחות",
        en: "show less",
        ar: "<ar>show less<ar>",
        am: "<am>show less<am>",
        ru: "<ru>show less<ru>"
    },
    SEND_EMAIL: {
        he: "שלח את ההמלצות למייל",
        en: "Send the recommendations by email",
        ar: "<ar>Send the recommendations by email<ar>",
        am: "<am>Send the recommendations by email<am>",
        ru: "<ru>Send the recommendations by email<ru>"
    },
    INSERT_EMAIL: {
        he: "הכנס כתובת אימייל",
        en: "Enter an email address",
        ar: "<ar>Enter an email address<ar>",
        am: "<am>Enter an email address<am>",
        ru: "<ru>Enter an email address<ru>"
    },
    INCORRECT_EMAIL: {
        he: "*כתובת אימייל שגויה",
        en: "*Incorrect email address",
        ar: "<ar>*Incorrect email address<ar>",
        am: "<am>*Incorrect email address<am>",
        ru: "<ru>*Incorrect email address<ru>"
    },
    SEND: {
        he: "שלח",
        en: "send",
        ar: "<ar>send<ar>",
        am: "<am>send<am>",
        ru: "<ru>send<ru>"
    },
    CANCEL: {
        he: "בטל",
        en: "cancel",
        ar: "<ar>cancel<ar>",
        am: "<am>cancel<am>",
        ru: "<ru>cancel<ru>"
    },
    FILL_FEEDBACK: {
        he: "למילוי משוב על השאלון לחץ כאן",
        en: "fill feedback",
        ar: "<ar>fill feedback<ar>",
        am: "<am>fill feedback<am>",
        ru: "<ru>fill feedback<ru>"
    }
});

export default RiskLevelTranslation;