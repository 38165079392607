import Condition from "../interfaces/Condition.interface";

function validateCondition(condition: any): condition is Condition {
    if (typeof condition !== "object") {
        return false;
    }
    
    const keys = Object.keys(condition);
    
    if (keys.length !== 1 || (!condition.and && !condition.or && !condition.eq)) {
        return false;
    }
    
    if (condition.and && (!Array.isArray(condition.and) || condition.and.length !== 2 || !validateCondition(condition.and[0]) || !validateCondition(condition.and[1]))) {
        return false;
    }

    if (condition.or && (!Array.isArray(condition.or) || condition.or.length !== 2 || !validateCondition(condition.or[0]) || !validateCondition(condition.or[1]))) {
        return false;
    }

    if (condition.eq &&
        (
            !Array.isArray(condition.eq)
            ||
            typeof condition.eq[0] !== "string"
            ||
            typeof condition.eq[1] !== "string"
            ||
            !condition.eq[0].match(/^q[1-9][0-9]*$/)
            ||
            !condition.eq[1].match(/^answer_[1-9][0-9]*$/)
        )) {
        return false;
    }

    return true;
}

export default validateCondition;
