import { FC, useEffect, useState } from "react";
import { Phone, Link, ExpandMore } from "@material-ui/icons";

import { hospitalCenter, hospitalCenters } from "../consts/hospitalCenters";
import "../scss/HospitalTable.scss";
import { IconButton } from "@material-ui/core";

interface header {
    title?: string;
    weight?: number;
}

export interface HospitalTableProps {
    dir?: "rtl" | "ltr";
    headers: header[];
    data: hospitalCenters;
}

const HospitalTable: FC<HospitalTableProps> = (props) => {
    const { dir = "ltr", headers, data } = props;

    const [expandList, setExpandList] = useState([...Array(data.length)].map(i => false));

    useEffect(() => {
        setExpandList([...Array(data.length)].map(i => false));
    }, [data]);

    const expandFunction = (index: number) => {
        let copy = [...expandList];
        copy[index] = !copy[index];
        setExpandList(copy);
    }

    return (
        <div className="hospital_center_table_containter">
            <div className={`hospital_center_table_headers`} style={{ display: "flex" }} dir={dir}>
                {headers.map((header, index) => {
                    return (
                        <span style={{ flex: header.weight }} key={index}>
                            {header?.title}
                        </span>
                    );
                })}
            </div>
            <div className={`hospital_center_table_content`} dir={dir}>
                {data.map((center: hospitalCenter, index: number) => {
                    return (
                        <div className="hospital_center_table_row_container" key={`${center.address}${index}`}>
                            <div className="hospital_center_table_row" style={{ display: "flex" }}>
                                <span style={{ flex: headers[0].weight }}>
                                    {center.hospitalName}
                                </span>
                                <span style={{ flex: headers[1].weight }}>
                                    {center.city}
                                </span>
                                <span style={{ flex: headers[2].weight }}>
                                    {center.ageGroup}
                                </span>
                                <span style={{ flex: headers[3].weight }}>
                                    {center.type}
                                </span>
                                <span className="link" style={{ flex: headers[4].weight }}>
                                    {center.link &&
                                        <IconButton className="icon_button" href={center.link} target="_blank" title="אתר אינטרנט">
                                            <Link className="link" />
                                        </IconButton>}
                                </span>
                                <span style={{ flex: headers[5].weight }}>
                                    <IconButton className="icon_button" href={`tel:${center.directPhoneNumber || center.subPhoneNumber || ""}`} title="חייג">
                                        <Phone className="phone" />
                                    </IconButton>
                                </span>
                                <span style={{ flex: headers[6].weight }}>
                                    <IconButton className="icon_button" style={expandList[index] ? { transform: "rotate(180deg)" } : { transform: "rotate(0deg)" }} onClick={() => { expandFunction(index) }}>
                                        <ExpandMore className="expand" />
                                    </IconButton>
                                </span>
                            </div>
                            <div className="row_expandable" style={expandList[index] ? { display: "block" } : { display: "none" }}>
                                {center.address && <div>כתובת: {center.address}</div>}
                                {center.district && <div>מחוז: {center.district}</div>}
                                {center.subPhoneNumber && <div>טלפון מרכזיה: {center.subPhoneNumber}</div>}
                                {center.directPhoneNumber && <div>טלפון ישיר: {center.directPhoneNumber}</div>}
                            </div>
                        </div>);
                })}
            </div>
        </div>
    );
}

export default HospitalTable;