import { FC } from "react";

import "../scss/HomeText.scss";

interface HomeTextProps {
    buttonText: string;
    texts: string[];
    onStart: () => void;
}

const HomeText: FC<HomeTextProps> = ({ buttonText, texts, onStart }) => {
    return (
        <div className="home_text_container">
            <div>
                {
                    texts.map((text, index) => (
                        <div key={index} className="text">{text}</div>
                    ))
                }
            </div>
            <div className="button_container">
                <button onClick={onStart}>
                    {buttonText}
                </button>
            </div>
        </div>
    );
}

export default HomeText;