import { action, computed, makeObservable, observable } from 'mobx';

import { COOKIE_NAME, STOP_TIME_STORAGE_NAME, USER_STORAGE_NAME } from '../consts/keys';

import LANGUAGES, { DIRECTIONS } from '../consts/languages';

import validateUser from '../common/functions/validateUser';
import { QUESTIONNAIRE_MAX_TIME } from '../consts/questionnaireMaxTime';
import { historyRef } from '../App';
import LanguageType from '../common/types/language.type';

class UserStore {
    firstSaveStatus = true;

    language = LANGUAGES[0].symbol;

    questionnaireStopped = false;
    stopTimeout: ReturnType<typeof setTimeout> | null = null;

    constructor() {
        makeObservable(this, {
            language: observable,
            questionnaireStopped: observable,
            direction: computed,
            restore: action,
            stopQuestionnaire: action,
            logout: action,
            setLanguage: action
        });

        this.stopQuestionnaire = this.stopQuestionnaire.bind(this);
    }

    get direction(): "rtl" | "ltr" {
        return DIRECTIONS[this.language];
    }

    setLanguage(language: LanguageType): void {
        this.language = language;
    }

    startQuestionnnaire(): void {
        const now = Date.now();
        const stopTime = now + QUESTIONNAIRE_MAX_TIME;
        sessionStorage.setItem(STOP_TIME_STORAGE_NAME, stopTime.toString());

        this.setStopTimeout(QUESTIONNAIRE_MAX_TIME);
    }

    setStopTimeout(time: number): void {
        if (this.stopTimeout) {
            clearTimeout(this.stopTimeout);
        }
        this.stopTimeout = setTimeout(this.stopQuestionnaire, time);
    }

    stopQuestionnaire(): void {
        if (historyRef.current?.location.pathname === "/questionnaire") {
            this.questionnaireStopped = true;
        }
    }


    saveStatus(): void {
        const data = {
            language: this.language
        };

        sessionStorage.setItem(USER_STORAGE_NAME, JSON.stringify(data));
    }

    deleteStorage(): void {
        sessionStorage.removeItem(USER_STORAGE_NAME);
        sessionStorage.removeItem(STOP_TIME_STORAGE_NAME);
    }

    canRestore(): boolean {
        const stopTimeStr = sessionStorage.getItem(STOP_TIME_STORAGE_NAME);
        if (!stopTimeStr) {
            return false;
        }

        const stopTime = parseInt(stopTimeStr);
        if (Number.isNaN(stopTime)) {
            sessionStorage.removeItem(STOP_TIME_STORAGE_NAME);
            return false;
        }

        const strData = sessionStorage.getItem(USER_STORAGE_NAME);
        if (!strData) {
            return false;
        }
        try {
            const data = JSON.parse(strData);
            const isValid = validateUser(data);

            return isValid;
        } catch (err) {
            return false;
        }
    }

    restore(): void {
        const strData = sessionStorage.getItem(USER_STORAGE_NAME)!;
        const stopTime = parseInt(sessionStorage.getItem(STOP_TIME_STORAGE_NAME)!);

        try {
            const data = JSON.parse(strData);

            this.setStopTimeout(stopTime - Date.now());
            this.language = data.language;
        } catch (err) {
            console.log("can't restore user", err);
        }
    }

    get isAuthenticated(): boolean {
        return document.cookie.split("; ").some(cookie => cookie.split("=")[0] === COOKIE_NAME);
    }

    logout(): void {
        document.cookie = `${COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        this.questionnaireStopped = false;
    }
}

const userStore = new UserStore();

export default userStore;
(window as any).scores = () => {
    const store = (window as any).store
    let qScores = store.questionsStore.getQuestionsScore();
    let section = qScores[0].section;
    const scores: any = {};
    for (let obj of qScores) {
        if (obj.section !== section) {
            section = obj.section
        }

        if (!scores[section]) {
            scores[section] = 0;
        }
        scores[section] += obj.score;
    }

    return scores;
}

(window as any).details = () => {
    const store = (window as any).store
    const userQuestions = store.questionsStore.questions.filter((question: any) => question.isUserQuestion && question.selectedIndex !== undefined && question.answers[question.selectedIndex].symbol !== "none");
    const userData = userQuestions.map((question: any) => question.answers[question.selectedIndex].symbol);
    alert(`${userData.join("-")}    ${store.riskLevelStore.riskLevelName}`)
    return `${userData.join("-")}    ${store.riskLevelStore.riskLevelName}`;
}
