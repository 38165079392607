export type hospitalCenter = {
    hospitalName: string;
    city?: string;
    ageGroup: string;
    type: string;
    link?: string;
    district: string;
    address: string;
    directPhoneNumber?: string;
    subPhoneNumber?: string;
}

export type hospitalCenters = hospitalCenter[];

export const HOSPITAL_CENTERS: hospitalCenters = [
    {
        district: "צפון",
        hospitalName: "מזור",
        ageGroup: "בוגרים",
        directPhoneNumber: "04-9559608",
        subPhoneNumber: "04-9559595",
        link: "http://www.mazra.org.il/",
        city: "עכו",
        address: "​רחוב דוכיפת 2,  ד. נ אשרת, מיקוד 25200",
        type: "ממשלתי"
    },
    {
        district: "חיפה",
        hospitalName: "מעלה הכרמל",
        ageGroup: "כל הגילאים",
        directPhoneNumber: "04-8559202",
        subPhoneNumber: "04-8559222",
        link: "http://www.tiratcarmel-med.org.il/",
        city: "טירת הכרמל",
        address: "​ת.ד 9, מיקוד 30200",
        type: "ממשלתי"
    },
    {
        district: "חיפה",
        hospitalName: "שער מנשה",
        ageGroup: "בוגרים",
        directPhoneNumber: "04-6278719",
        subPhoneNumber: "04-6278888",
        link: "http://www.shaar-menashe.org/",
        city: "שער מנשה",
        address: "​ד.נ חפר  38814",
        type: "ממשלתי"
    },
    {
        district: "מרכז",
        hospitalName: "באר יעקב",
        ageGroup: "בוגרים",
        subPhoneNumber: "08-9258243",
        link: "http://www.beer-ness.co.il/course.asp",
        address: "דרך חיים 1, ת.ד 1, מיקוד�",
        city: "באר יעקב",
        type: "ממשלתי"
    },
    {
        district: "מרכז",
        hospitalName: "לב השרון (פרדסיה)",
        ageGroup: "בוגרים",
        subPhoneNumber: "09-8981111",
        directPhoneNumber: "09-8981221",
        link: "http://www.lev-hasharon.co.il/",
        address: "​ת.ד 90000 נתניה, מיקוד 42100",
        type: "ממשלתי"
    },
    {
        district: "מרכז",
        hospitalName: "נס ציונה",
        ageGroup: "כל הגילאים",
        subPhoneNumber: "08-9258243",
        link: "http://www.beer-ness.co.il/Department1.asp?ID=2",
        address: `​רחוב תרמ"ג, ת.ד 1, מיקוד�`,
        city: "נס ציונה",
        type: "ממשלתי"
    },
    {
        district: "תל אביב",
        hospitalName: "אברבנאל",
        ageGroup: "כל הגילאים",
        directPhoneNumber: "03-5552626",
        link: "http://abarbanel.health.gov.il/",
        address: `​שדרות קק"ל 15`,
        city: "בת ים",
        type: "ממשלתי"
    },
    {
        district: "ירושלים",
        hospitalName: "איתנים",
        ageGroup: "כל הגילאים",
        subPhoneNumber: "02-5705111",
        link: "http://www.psjer.org.il/?CategoryID=255",
        address: "ד.נ צפון יהודה ירושלים, 90972",
        type: "ממשלתי"
    },
    {
        district: "ירושלים",
        hospitalName: "כפר שאול",
        ageGroup: "בוגרים",
        subPhoneNumber: "02-6551551",
        link: "http://www.psjer.org.il/?CategoryID=254",
        address: "​גבעת שאול ב', ירושלים 91060",
        city: "ירושלים",
        type: "ממשלתי"
    },
    {
        district: "דרום",
        hospitalName: "באר שבע",
        ageGroup: "כל הגילאים",
        subPhoneNumber: "08-6401401",
        address: "​רחוב הצדיק מירושלים 2 ",
        city: "באר שבע",
        type: "ממשלתי"
    },
    {
        district: "מרכז",
        hospitalName: "גהה",
        ageGroup: "כל הגילאים",
        directPhoneNumber: "03-9258258",
        link: "http://hospitals.clalit.co.il/hospitals/geha/he-il/Pages/homepage.aspx",
        address: "​​רחוב הלסינקי 1, ת.ד 120 ",
        city: "פתח תקוה",
        type: "שירותי בריאות כללית"
    },
    {
        district: "מרכז",
        hospitalName: "שלוותה",
        ageGroup: "כל הגילאים",
        directPhoneNumber: "09-7478532",
        link: "http://hospitals.clalit.co.il/hospitals/Shalvata/he-il/Pages/homepage.aspx",
        address: "רחוב עליית הנוער 13, ת.ד  94",
        city: "הוד השרון",
        type: "שירותי בריאות כללית"
    },
    {
        district: "ירושלים",
        hospitalName: "הרצוג",
        ageGroup: "בוגרים",
        directPhoneNumber: "02-5316817",
        link: "http://http//www.herzoghospital.org/index.asp?id=149",
        address: "​ת.ד 3900",
        city: "ירושלים",
        type: "ציבורי"
    },
    {
        district: "מרכז",
        hospitalName: "אילנית",
        ageGroup: "בוגרים",
        directPhoneNumber: "04-6378760",
        address: "​רחוב הנרקיס 1, ת.ד 1080, מיקוד 37110",
        city: "פרדס חנה",
        type: "פרטי"
    },
    {
        district: "מרכז",
        hospitalName: "החלמה ונופש",
        ageGroup: "בוגרים",
        directPhoneNumber: "09-8652828",
        address: "​רחוב משולם ראט, מיקוד 42126",
        city: "נתניה",
        type: "פרטי"
    },
    {
        district: "צפון",
        hospitalName: "בית החולים האנגלי בנצרת",
        ageGroup: "בוגרים",
        directPhoneNumber: "04-6028817",
        link: "http://www.nazhosp.com/newver/index.php",
        address: "​ת.ד 11, מיקוד 16100",
        city: "נצרת",
        type: "בתי חולים כלליים"
    },
    {
        district: "צפון",
        hospitalName: "המרכז הרפואי לגליל המערבי",
        ageGroup: "קטינים",
        directPhoneNumber: "04-9107460",
        link: "http://www.wgh.org.il/Pages.aspx?pg=division&CategoryID=502&ID=47",
        address: "​ת.ד 21, מיקוד 22100",
        city: "נהריה",
        type: "בתי חולים כלליים"
    },
    {
        district: "צפון",
        hospitalName: "העמק",
        ageGroup: "בוגרים",
        directPhoneNumber: "04-6494351",
        link: "http://hospitals.clalit.co.il/hospitals/emek/he-il/Clinics/MentalHealth/Pages/Mental%20Health%20Department.aspx",
        address: "​שדרות יצחק רבין, מיקוד 18101",
        city: "עפולה",
        type: "בתי חולים כלליים"
    },
    {
        district: "צפון",
        hospitalName: "זיו",
        ageGroup: "כל הגילאים",
        directPhoneNumber: "​04-6828067",
        link: "http://www.ziv.org.il/?CategoryID=2129&ArticleID=3189&dbsAuthToken={79CC7D8D-9DF4-4880-B68E-98610B1EE969}&rnd=9884154",
        address: `רחוב הרמב"ם, ת.ד 1008, מיקוד 13110`,
        city: "צפת",
        type: "בתי חולים כלליים"
    },
    {
        district: "חיפה",
        hospitalName: "הלל יפה",
        ageGroup: "בוגרים",
        directPhoneNumber: "04-6304304",
        link: "http://hy.health.gov.il/?CategoryID=234",
        address: `​ת.ד 169, מיקוד 38100`,
        city: "חדרה",
        type: "בתי חולים כלליים"
    },
    {
        district: "חיפה",
        hospitalName: `רמב"ם`,
        ageGroup: "בוגרים",
        directPhoneNumber: "04-7773036",
        link: "http://www.rambam.org.il/%D7%9E%D7%97%D7%9C%D7%A7%D7%95%D7%AA+%D7%95%D7%9E%D7%A8%D7%A4%D7%90%D7%95%D7%AA/%D7%90%D7%92%D7%A3+%D7%91%D7%A8%D7%99%D7%90%D7%95%D7%AA+%D7%94%D7%A0%D7%A4%D7%A9/%D7%90%D7%A9%D7%A4%D7%95%D7%96+%D7%A4%D7%A1%D7%99%D7%9B%D7%99%D7%90%D",
        address: `​ת.ד 9602, מיקוד 31096 בניין ישן (מנדלסון), קומה: 2​`,
        city: "חיפה",
        type: "בתי חולים כלליים"
    },
    {
        district: "מרכז",
        hospitalName: "שניידר",
        ageGroup: "קטינים",
        directPhoneNumber: "03-9253617",
        link: "http://www.schneider.org.il/Index.asp?CategoryID=142&ArticleID=257",
        address: "​רחוב קפלן 14, ת.ד. 559, מיקוד 49202​",
        city: "פתח תקוה",
        type: "בתי חולים כלליים"
    },
    {
        district: "תל אביב",
        hospitalName: "שיבא",
        ageGroup: "כל הגילאים",
        directPhoneNumber: "03-5302466",
        link: "http://rehabilitation.sheba.co.il/Psychiatric_Division/",
        address: `בית החולים שיב"א`,
        city: "תל השומר",
        type: "בתי חולים כלליים"
    },
    {
        district: "ירושלים",
        hospitalName: "הדסה עין כרם",
        ageGroup: "כל הגילאים",
        directPhoneNumber: "02-6777183",
        link: "http://www.hadassah.org.il/medical-care/departments/%D7%A4%D7%A1%D7%99%D7%9B%D7%99%D7%90%D7%98%D7%A8%D7%99%D7%94.aspx",
        address: "​קרית הדסה, תא דואר 12000, מיקוד 91120​",
        city: "ירושלים",
        type: "בתי חולים כלליים"
    },
    {
        district: "דרום",
        hospitalName: "ברזילי",
        ageGroup: "בוגרים",
        directPhoneNumber: "08-6745398",
        link: "http://www.barzilaimc.org.il/?CategoryID=240&ArticleID=866",
        address: "​רחוב ההסתדרות 2, מיקוד 78278​",
        city: "אשקלון",
        type: "בתי חולים כלליים"
    },
]

export default HOSPITAL_CENTERS;