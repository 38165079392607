import { FC } from "react";
import { observer } from "mobx-react";

import { useQuestionaire } from "../stores/questionnaire.store";
import CustomSelect from "./CustomSelect";
import LANGUAGES from "../consts/languages";
import LanguageType from "../common/types/language.type";

const LanguageSelect: FC = () => {
    const questionaireStore = useQuestionaire();

    return (
        <CustomSelect
            value={questionaireStore.userStore.language}
            options={LANGUAGES}
            onChange={value => questionaireStore.userStore.setLanguage(value as LanguageType)}
        />
    );
}

export default observer(LanguageSelect);