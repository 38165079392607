import { FC } from "react";
import LanguageSelect from "./LanguageSelect";

import '../scss/HomeInnerBar.scss';

interface HomeInnerBarProps {
    title: string;
}


const HomeInnerBar: FC<HomeInnerBarProps> = ({ title }) => {
    return (
        <div className="home_inner_bar_container">
            <h1 className="title">
                {title}
            </h1>
            <LanguageSelect />
        </div>
    );
}

export default HomeInnerBar;