import { FC, useState } from 'react';
import axios from 'axios';
import { observer } from 'mobx-react';

import { questionaireStore } from '../stores/questionnaire.store';
import { emailRegex } from "../consts/regex";
import RiskLevelTranslation from '../translations/riskLevel.translation';

import "../scss/SendPopup.scss";

export interface SendPopupProps {
    closePopup: () => void;
    suggestion: HTMLDivElement | null;
}

const SendPopup: FC<SendPopupProps> = ({ closePopup, suggestion }) => {
    const { language, direction } = questionaireStore.userStore;

    const [input, setInput] = useState("");
    const [error, setError] = useState(false);

    const onInputChange = (value: string) => {
        setInput(value);
    }

    const onSend = () => {
        if (!input.match(emailRegex)) {
            setError(true);
            return;
        }

        //should never happen
        if (!suggestion) {
            return;
        }

        //find all iframes - we can't send iframes via email so we delete them from our html string and add them as links.
        const iframes = suggestion.querySelectorAll("iframe");
        const links: string[] = [];
        iframes.forEach(iframe => {
            const splitted = iframe.src.split("/");
            const videoId = splitted[splitted.length - 1];
            const link = `https://youtu.be/${videoId}`;
            links.push(link);
        });

        const innerHTML = suggestion.innerHTML;
        const withoutIframes = innerHTML.split(/<iframe.*>.*<\/iframe>/).join("");
        const withoutIframesWithLinks = withoutIframes + "<br></br>" + links.join("<br></br>");

        axios.post("/api/mailer", {
            mail: input,
            suggestion: withoutIframesWithLinks
        });

        closePopup();
    }

    return (
        <div className="send_popup_page_container" >
            <div className="send_popup_container" dir={direction}>
                <div>
                    {RiskLevelTranslation.INSERT_EMAIL[language]}
                </div>
                <div className="send_popup_input_container">
                    <input type="text" value={input} onChange={(e) => { onInputChange(e.target.value as string) }} />
                    {error && <strong className="error">{RiskLevelTranslation.INCORRECT_EMAIL[language]}</strong>}
                </div>
                <div className="button_container">
                    <button className="send_button" onClick={onSend}>{RiskLevelTranslation.SEND[language]}</button>
                    <button className="cancel_button" onClick={closePopup}>{RiskLevelTranslation.CANCEL[language]}</button>
                </div>
            </div>
        </div>
    );
}

export default observer(SendPopup);