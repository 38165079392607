import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

export interface AnswerButtonProps {
    text: string,
    selected: boolean,
    onClick: () => void
}

const AnswerButton: FC<AnswerButtonProps> = ({ text, selected, onClick }) => {

    return (
        <button className={`answer_button ${selected ? "active" : ""}`} onClick={onClick}>
            {text}
            <div className='check_icon_div'>
                {
                    <FontAwesomeIcon icon={["fas", "check"]} className='checkIcon' />
                }
            </div>
        </button>
    );
}

export default AnswerButton;