import LanguageType from "../common/types/language.type";

export const DIRECTIONS: Record<string, "rtl" | "ltr"> = {
    he: "rtl",
    ar: "rtl",
    en: "ltr",
    ru: "ltr",
    am: "ltr"
}
//TODO add the languages
const LANGUAGES: { text: string, symbol: LanguageType }[] = [
    {
        text: 'עברית',
        symbol: "he"
    }
];

export default LANGUAGES;