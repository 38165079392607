import { useReducer } from "react";

const popupActionType = {
  OPEN: "OPEN",
  CLOSE: "CLOSE",
} as const;

export type PopupActionType =
  (typeof popupActionType)[keyof typeof popupActionType];

export function usePopupReducer(initialValue: boolean = false) {
  const popupReducer = (state: boolean, action: { type: PopupActionType }) => {
    switch (action.type) {
      case "OPEN":
        return true;
      case "CLOSE":
        return false;
      default:
        return !!state;
    }
  };
  const [showPopup, dispatch] = useReducer(popupReducer, initialValue);

  return { showPopup, dispatch };
}
