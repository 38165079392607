import { FC, useState } from "react";
import { observer } from "mobx-react";

import { useQuestionaire } from "../stores/questionnaire.store";

import HomeInnerBar from "../components/HomeInnerBar";
import HomeTopBar from "../components/HomeTopBar";
import HomeText from '../components/HomeText';

import HomeTranslation from '../translations/home.translation';
import ErrorPopup from "../components/ErrorPopup";
import Keywords from "../components/Keywords";

import "../scss/Home.scss";

const Home: FC = () => {

    const questionaireStore = useQuestionaire();
    const { language } = questionaireStore.userStore;

    const [showError, setShowError] = useState(false);

    const handleStart = () => {
        questionaireStore.createUser()
            .then(() => {
                setShowError(false);
            })
            .catch(() => {
                setShowError(true);
            });
    }

    return (
        <>
            <div className="home_container" dir={questionaireStore.userStore.direction}>
                <HomeInnerBar
                    title={HomeTranslation.TITLE[language]}
                />
                <HomeText
                    buttonText={HomeTranslation.START[language]}
                    texts={HomeTranslation.TEXT[language]}
                    onStart={handleStart}
                />
            </div>
            <ErrorPopup isOpen={showError} onClick={handleStart} />
            <HomeTopBar />
            <Keywords />
        </>
    );
}

export default observer(Home);